<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="policies">Insurance Policies</v-tab>
            <v-tab key="payers">Payers</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="policies">
              <v-master-insurance></v-master-insurance>
            </v-tab-item>
            <v-tab-item key="payers">
              <v-master-payer></v-master-payer>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
